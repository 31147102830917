<template>
	<b-row>
		<b-col>
			<b-card title="Account" class="border-0">
				<!-- Account -->
				<div class="d-flex justify-content-between align-items-center mb-3">
					<!-- Profile -->
					<p class="mb-0">{{ profile.mobile }}</p>
					<!-- Add Logout Button -->
					<b-button @click="logoutClicked" size="sm">Logout</b-button>
				</div>

				<hr class="mt-3" />

				<!-- Prescription Button -->
				<b-button v-if="enablePrescription" @click="showPrescriptions" variant="outline-primary" class="mt-3" pill block>
					Prescriptions
				</b-button>

				<!-- Address Button -->
				<b-button @click="showAddresses" variant="outline-primary" class="mt-3" pill block>
					Addresses
				</b-button>

				<!-- Change Password Div -->
				<div>
					<!-- Change Password Button -->
					<b-button @click="onChangePasswordClick" variant="outline-primary" class="mt-3" pill block>
						Change Password
					</b-button>

					<!-- CHANGE PASSWORD MODAL -->
					<ChangePasswordForm v-model="displayChangePasswordModal" />
				</div>
			</b-card>
		</b-col>
	</b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getProfile } from "../../../apis/user";

import ChangePasswordForm from "../../../components/account/ChangePasswordForm.vue";

export default {
	components: { ChangePasswordForm },
	created() {
		getProfile(this.token).then((data) => {
			this.profile = { ...data };
		});
	},
	data() {
		return {
			profile: {
				mobile: "",
			},
			displayChangePasswordModal: false,
		};
	},
	computed: {
		...mapGetters({
			token: "login/getToken",
			enablePrescription: "getEnablePrescription",
		}),
	},
	methods: {
		...mapActions({
			logout: "login/logout",
		}),
		logoutClicked() {
			this.logout().then(() => {
				// redirect back to dashboard
				this.$router.replace({ name: "StoreDashboard" });
			});
		},
		showAddresses() {
			// redirect to addresses
			this.$router.push({ name: "UserAddresses" });
		},
		showPrescriptions() {
			this.$router.push({ name: "UserPrescriptions" });
		},
		onChangePasswordClick() {
			this.displayChangePasswordModal = true;
		},
	},
};
</script>
